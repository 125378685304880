import React from 'react'
import { Typography } from 'antd'
import LandingLayout from '@layouts/LandingLayout'
import Helmet from 'react-helmet'

export default () => (
  <LandingLayout>
    <div className='flex flex-col items-center'>
      <iframe src="https://tuscan.retool.com/embedded/public/cfe44026-fe61-4c91-bf31-c6f74677bf86" title="vendor-tool" style={{width:'100%',height:'1200px'}}></iframe>
    </div>
  </LandingLayout>
)
